<template>
  <v-container
      fluid
      align="center"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
            elevation="5"
            class="pb-10"
            inline
        >
          <v-tabs
              color="accent"
              grow
              show-arrows
              v-model="tab"
          >
            <v-tab light class="text-no-wrap" id="v-step-0">
              M360API &reg;
            </v-tab>
            <v-tab light class="text-no-wrap" id="v-step-3">
              Product News
            </v-tab>
            <v-tab light class="text-no-wrap" id="v-step-4">
              Case Studies
            </v-tab>
            <v-tab-item>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p class="text-muted">Corsair M360 SAAS allows you to create Enterprise and SAAS M360API projects.
                    </p>
                  </v-col>
                </v-row>
                <v-divider class="mt-3"></v-divider>
                <v-row>
                  <v-col md="6" sm="12" xs="12">
                    <v-hover
                        v-slot="{ hover }"
                        open-delay="50"
                    >
                      <v-card
                          elevation="2"
                          class="pa-5"
                          @click="createProject('saas')"
                          :color="hover ? 'secondary': ''"
                      >
                        <v-card-title class="mb-3" :class="{'secondary--text': !hover}" id="v-step-1">
                          <v-icon class="mr-2" :class="{'secondary--text': !hover}">mdi-cloud</v-icon>
                          M360API &reg; SAAS
                        </v-card-title>
                        <v-card-text :class="(hover) ? 'white--text': ''">
                          Get Started building your project and select a package that fits your needs.
                        </v-card-text>
                        <v-list
                            id="saasFeatures"
                            :flat="true"
                            :tile="true"
                        >
                          <v-subheader>Features</v-subheader>
                          <v-list-item-group>
                            <v-list-item
                                v-for="(item, i) in saasFeatures"
                                :key="i"
                            >
                              <v-list-item-icon>
                                <v-icon :class="(hover) ? '': 'secondary--text'" v-text="item.icon"></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title :class="(hover) ? 'white--text': ''" v-text="item.text"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col md="6" sm="12" xs="12">

                    <v-hover
                        v-slot="{ hover }"
                        open-delay="50"
                    >
                      <v-card
                          elevation="2"
                          class="pa-5"
                          @click="createProject('enterprise')"
                          :color="hover ? 'accent': ''"
                      >
                        <v-card-title class="mb-3" :class="{'accent--text': !hover}" id="v-step-2">
                          <v-icon class="mr-2" :class="{'accent--text': !hover}">mdi-server</v-icon>
                          M360API &reg; Enterprise
                        </v-card-title>
                        <v-card-text :class="(hover) ? 'white--text': ''">
                          For larger enterprises that want an unlimited multi-cloud or on-premise solution.
                        </v-card-text>
                        <v-list
                            id="enterpriseFeatures"
                            :flat="true"
                            :tile="true"
                        >
                          <v-subheader>Features</v-subheader>
                          <v-list-item-group>
                            <v-list-item
                                v-for="(item, i) in enterpriseFeatures"
                                :key="i"
                            >
                              <v-list-item-icon>
                                <v-icon :class="(hover) ? '': 'accent--text'" v-text="item.icon"></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title :class="(hover) ? 'white--text': ''" v-text="item.text"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p class="text-muted">
                      Stay up to date and find out about the releases and product updates of Corsair M360API.
                    </p>
                  </v-col>
                </v-row>
                <v-divider class="mt-3"></v-divider>
                <v-row>
                  <v-col cols12>
                    <v-alert colored-border border="top" elevation="2" color="success" type="info">Coming Soon!</v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p class="text-muted">
                      Learn how others have used Corsair M360API to improve their products.
                    </p>
                  </v-col>
                </v-row>
                <v-divider class="mt-3"></v-divider>
                <v-row>
                  <v-col cols12>
                    <v-alert colored-border border="top" elevation="2" color="warning" type="info">Coming Soon!</v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>
          </v-tabs>

        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
        v-model="tourDialog"
        width="80%"
    >
      <v-card>
        <v-card-title class="text-h3 white--text pb-3" :style="{'background-color': '#2E9EF2'}">
          Welcome to <strong class="black--text">CorsairM360</strong> SAAS Platform
        </v-card-title>

        <v-card-text class="text-center">
          <p class="text-h2 text-md-h1">Thank you for choosing M360API.</p>
          <p class="text-h4 text-md-h3">This is where innovation starts.</p>
          <p>In this quick tour, we'll show you how to get started and where to find things.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
              small
              text
              depressed
              @click="closeTour"
          >
            Don't show this again
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              small
              color="primary"
              @click="startTour"
          >
            Start Tour
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import globalMixins from "@/mixins/globalMixins";

export default {
  name: 'DashboardPage',

  mixins: [ globalMixins ],

  methods: {
    createProject( type ) {
      this.$router.push( { name: 'createProject', params: { type: type } } )
    },

    showDialog(){
      if(!this.$cookies.get('close_corsair_saas_tour')){
        this.tourDialog = true;
      }
    },

    closeTour(){
      this.tourDialog = false;
      this.$cookies.set( 'close_corsair_saas_tour', true, "1y" );
    },

    startTour(){
      this.$emit('beginTour', true);
      this.closeTour();
    }
  },

  props: {
    enterpriseFeatures: {
      type: Array
    },
    saasFeatures: {
      type: Array
    },
    selectedTab :{
      type: Number
    }
  },

  watch: {
    selectedTab(val){
      this.tab = val;
    }
  },

  data(){
    return {
      tab: null,
      tourDialog: false
    }
  },

  created(){
    this.showDialog();
  },
}
</script>
<style scoped lang="scss">

.v-tabs .v-tab:hover:not(.v-tab--active) {
  background-color: $secondary !important;
  caret-color: $secondary !important;;
}

.v-tabs .v-tab--active {
  color: white;
  background-color: $accent !important;
  caret-color: $accent !important;;
}
</style>